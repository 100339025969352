import Vue from "vue";
import prettyBytes from "pretty-bytes";
import {
  EagleListConfigInterface,
  TableDataType,
} from "@/components/list/types/list";
class listConfig {
  protected vm?: Vue;
  protected listKey?: string;

  get(vm: Vue, listKey: string): EagleListConfigInterface {
    this.vm = vm;
    this.listKey = listKey;

    return {
    };
  }
}

export default new listConfig();
